$(function () {
	$('body').on('change', 'select.ajax', function (event) {
		event.preventDefault();
		const form = $(this).closest('form');
		form.submit();
	});

	$('body').on('click', 'a.ajax', function (event) {
		event.preventDefault();
		window.history.pushState({href: $(this).attr('href')}, '', $(this).attr('href'));
		ajaxCall(null, null, $(this).attr('href'));
	});

	$('body').on('change', 'input:radio.ajax', function (event) {
		event.preventDefault();
		const form = $(this).closest('form');
		form.submit();
	});

	$('body').on('submit', 'form.ajax', function (event) {
		event.preventDefault();
		const form = $(this);
		if (form.attr('data-type') === 'configurator') {
			startConfigurator(form);
		}
	});

	function startConfigurator(form) {
		const formData = serializeFormData(form);
		const qs = getQueryString(form, formData);
		window.history.pushState({href: qs}, '', qs);
		formData['ts'] = Date.now();
		ajaxCall(form, formData);
	};

	function ajaxCall(form, formData, customUrl) {
		if (customUrl) {
			$.ajax(customUrl, {
				type: 'get',
				beforeSend: function () {
					$('.loader-overlay').show();
				},
				complete: function () {
					$('.loader-overlay').hide();
				},
				success: function (data) {
					jQuery.each(data.snippets, function (snippet, currentValue) {
						$('#' + snippet).html(currentValue);
					});
					$(window).trigger('configuratorSwitch');
				},
				error: function () {
					console.log('error');
				}
			});
		} else if (form) {
			$.ajax(form.attr('action'), {
				type: form.attr('method'),
				data: formData,
				beforeSend: function () {
					$('.loader-overlay').show();
				},
				complete: function () {
					$('.loader-overlay').hide();
				},
				success: function (data) {
					jQuery.each(data.snippets, function (snippet, currentValue) {
						$('#' + snippet).html(currentValue);
					});
					$(window).trigger('configuratorSwitch');
				},
				error: function () {
					console.log('error');
				}
			});
		}
	}

	function serializeFormData(form) {
		const formData = form.serializeArray().reduce(function (obj, item) {
			obj[item.name] = item.value;
			return obj;
		}, {});

		return formData;
	};

	function getQueryString(form, formData) {
		return form.attr('action') + (Object.keys(formData).length ? '?' : '') + Object.keys(formData)
				.map(key => `${key}=${formData[key]}`)
				.join('&');
	}

	$(window).on('popstate', function (e) {
		// document.location.href = document.location.href; - // Bug - Maximum call stack size exceeded.
	});
});