$(function () {
	var typingTimer;                //timer identifier
	var doneTypingInterval = 400;  //time in ms (5 seconds)

	$('input[name=q]').keyup(function(){
		if(this.value.length >= 3) {
			clearTimeout(typingTimer);
			if ($('input[name=q]').val()) {
				typingTimer = setTimeout(autoComplete, doneTypingInterval);
			}
		}
	});
	function autoComplete() {
		$.ajax($('#searchForm').attr('action'), {
			type: 'GET',
			data: {
				q: $('input[name=q]').val(),
				ts: Date.now()
			},
			success: function(data) {
				$('.suggests').html(data.content);
				if (data.isResults) {
					$('[data-purpose="dropdown-search"]').addClass('active');
				}
			},
			error: function() {
				console.log('error');
			}
		});
	}
});