/*
 *
 * MIKROP
 * Author: Martin Winkler
 * url: http://www.martinwinkler.cz/
 *
 */

/**********************************************
VARIOUS
**********************************************/

// Scrollování ke kotvě
$(function() {
  $('.to-anchor').scrollToAnchor();
});

// Dropdown
$('[data-purpose="dropdown-trig"]').on('click', function() {
  var trigger = $(this);
  var li = trigger.closest('li');
  var id = trigger.data('id');
  var dropdown = $(document.getElementById(id));
  var dropdowns = $('[data-purpose="dropdown"]');
  var triggers = $('[data-purpose="dropdown-trig"]');
  var header = $('.header');

  if (!dropdown.hasClass('active')) {
    dropdowns.removeClass('active');
    triggers.closest('li').removeClass('active');
    dropdown.addClass('active');
    header.addClass('dropdown-opened');
    li.addClass('active');
  } else {
    dropdown.removeClass('active');
    header.removeClass('dropdown-opened');
    li.removeClass('active');
  }

  return false;
});

// Searching
$('[data-purpose="search-trig"]').on('click', function() {
  var trigger = $(this);
  var header = $('.header');
  var dropdown = $('[data-purpose="dropdown-search"]')

  if (!trigger.hasClass('active')) {
    header.addClass('search-opened');
    trigger.addClass('active');
  } else {
    header.removeClass('search-opened');
    trigger.removeClass('active');
    dropdown.removeClass('active');
    $('[data-purpose="search-header"]').val('').trigger('keyup');
  }

  return false;
});

$('[data-purpose="search-header"]').on('keyup', function() {
  var searchInputVal = $(this).val();

  if (searchInputVal.length > 0) {
    $('[data-purpose="dropdown-search"]').addClass('active');
    $('body').addClass('searching');
  } else {
    $('[data-purpose="dropdown-search"]').removeClass('active');
    $('body').removeClass('searching');
  }
});

// Scrollspy
$(function() {
  'use strict';

  var section = document.querySelectorAll('.anchor');
  var sections = {};
  var i = 0;

  $(window).on('load scroll', function() {
    var scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
    scrollPosition = scrollPosition + 1;

    Array.prototype.forEach.call(section, function(e) {
      sections[e.id] = $(e).offset().top;
    });

    for (i in sections) {
      if (sections[i] <= scrollPosition) {
        $('.scrollspy li').removeClass('active');
        $('.scrollspy a[href*=' + i + ']').closest('li').addClass('active');
      }
    }
  });
});

// Wrap table
$(function() {
  $('table').wrap('<div class="table-wrap"></div>');
});

// Language switcher
$('.lng > a').on('click', function() {
  var lng = $(this).closest('.lng');

  if (!lng.hasClass('active')) {
    lng.addClass('active');
  } else {
    lng.removeClass('active');
  }
  return false;
});

// Tabs
$(function() {
  $('.tabs').each(function() {
    var triggers = $(this).find('.tab-nav a');
    var tabs = $(this).find('.tab-content .tab');

    triggers.on('click', function() {
      var trigger = $(this);
      var id = $(this).attr('href');      

      if (!trigger.hasClass('active')) {
        triggers.removeClass('active');
        tabs.removeClass('active');
        
        tabs.filter(id).addClass('active');
        trigger.addClass('active');
      } else {
        tabs.removeClass('active');
        trigger.removeClass('active');
      }

      return false;
    });

    // If hash tag..
    //var hash = window.location.hash.substring(1);
    //$('[href="#' + hash + '"]').click();
  });

  $('.tabs-offer .tab-nav .item a').on('click', function() {   
    if ($(this).hasClass('active')) {
      $("html:not(:animated),body:not(:animated)").animate({
          scrollTop: $('.tabs-offer .tab-content').offset().top - $('.header .inner').height()
        }, {
          duration: 750,
          complete: function() {
            $("html:not(:animated),body:not(:animated)").animate({
              scrollTop: $('.tabs-offer .tab-content').offset().top - $('.header .inner').height()
            }, {
              duration: 50,
              complete: function() {
                //history.pushState(null, null, elementClick);
              }
            }
          );
          }
        }
      );

      $(this).closest('.tabs-offer').addClass('opened');
    } else {
      $(this).closest('.tabs-offer').removeClass('opened');
    }
  });
});

// Tippy
$('.tippy').each(function() {
  var trigger = 'mouseenter focus';
  var attr = $(this).attr('data-click');
  
  if (typeof attr !== typeof undefined && attr !== false) {
    trigger = 'click';
  }

  tippy(this, {
    content: $(this).find('.tippy-popup').html(),
    allowHTML: true,
    trigger: trigger,
    maxWidth: 275,
    //arrow: 'roundArrow'
  });
});

// Form contact
$('.form-contact').each(function() {
  var textInput = $(this).find('input.text, textarea');

  textInput.on('keyup', function() {
    if (this.value.length > 0) {
      $(this).closest('.cell').addClass('filled');
    } else {
      $(this).closest('.cell').removeClass('filled');
    }
  }).trigger('keyup');

  var select = $(this).find('select');

  select.on('change', function() {
    if (this.value.length > 0) {
      $(this).closest('.cell').addClass('filled');
    } else {
      $(this).closest('.cell').removeClass('filled');
    }
  }).trigger('change');
});

// Side nav
$('.side-nav .head').on('click', function() {
  var nav = $(this).closest('.side-nav');

  if (!nav.hasClass('opened')) {
    nav.addClass('opened');
  } else {
    nav.removeClass('opened');
  }
});

// Fixed header
$(window).on('load scroll', function() {
  var scrollTop = $(window).scrollTop();

  if (scrollTop > 50) {
    $('body').addClass('header-fixed');
  } else {
    $('body').removeClass('header-fixed');
  }
});

// Fix notification
$(function() {
  $('.fix-notification .close').on('click', function() {
    $('.fix-notification').removeClass('active');
  });
});

// Modal box
$(function() {
  $(window).on('load', function() {

    if (typeof modalCookie !== 'undefined') {
      var cookieisok = ""+ReadCookie(modalCookie);
      var testCookie = "";
      SetCookie("testCookie","ok",14);
      var testCookie = ""+ReadCookie('testCookie');
      
      if (cookieisok != "ok" && testCookie == "ok") {
        document.querySelector('.fix-notification .modal-trigger').click();

        $(window).on('modalClose', function() {
          SetCookie(modalCookie, 'ok', 14);
        });
      }
    }
  });
});

/**********************************************
FORM PICKER
**********************************************/

// Inputs styling
$('.form-picker input[type="radio"]').on('change', function() {
  var input = $(this);
  var inputs = $('input[name="'+ input.attr('name') +'"]');

  if (input.is(':checked')) {
    inputs.closest('label').removeClass('active');
    input.closest('label').addClass('active');
  } else {
    input.closest('label').removeClass('active');
  }
});

// First item active
// $('.form-picker .picker-item:nth-child(1)').addClass('active');

// Switch item on select/input change
// $('.form-picker select, .form-picker input').on('change', function() {
//   var item = $(this).closest('.picker-item');
//
//   item.removeClass('active').addClass('done');
//   item.next().addClass('active');
//
//   if (item.next().hasClass('picker-item-product')) {
//     item.next().next().addClass('active');
//   }
// });

// Switch item on click
// $('html').on('click', '.form-picker .picker-item.done .item-head', function() {
//   var item = $(this).closest('.picker-item');
//
//   $('.picker-item').removeClass('active');
//   $('.picker-item').removeClass('done');
//
//   item.addClass('active');
//   item.prevAll().addClass('done');
//
//   // reset select
//   item.find('select').val('');
//   item.nextAll().find('select').val('');
//
//   // reset input
//   item.find('input').prop('checked',false).closest('label').removeClass('active');
//   item.nextAll().find('input').prop('checked',false).closest('label').removeClass('active');
// });

/**********************************************
FORM STYLING
**********************************************/

$(window).on('load configuratorSwitch', function() {
  var checkbox = $('input[type="checkbox"]');

  checkbox.each(function(index, element) {
    var wrapper = $(element).wrap('<div class="checker"></div>');

    $(element).on('change', function() {
      wrapper = $(this).closest('.checker');

      if (element.checked) {
        wrapper.addClass('active');
      } else {
        wrapper.removeClass('active');
      }
    });
  });

  var select = $('select');

  select.each(function (index, element) {
    if ($(element).closest('.form-contact').length == 0) {
      var wrapper = $(element).wrap('<div class="selector"></div>');

      $(element).on('change', function () {
        wrapper = $(this).closest('.selector');

        if (element.checked) {
          wrapper.addClass('active');
        } else {
          wrapper.removeClass('active');
        }
      });
    }
  });
});

/**********************************************
REGIONS
**********************************************/

$(window).on('load configuratorSwitch', function() {
  $('.regions svg g').on('mouseover', function () {
    var id = $(this).attr('data-id');

    $('[data-id="' + id + '"]').addClass('active');
  });

  $('.regions svg g').on('mouseleave', function () {
    var id = $(this).attr('data-id');

    $('[data-id="' + id + '"]').removeClass('active');
  });

  $('.regions svg g').on('click', function () {
    var id = $(this).attr('data-id');
    var link = $('[data-id="' + id + '"]');

    if (!link.hasClass('opened')) {
      $('[data-purpose="region-item"]').removeClass('opened');
      link.addClass('opened');
    } else {
      link.removeClass('opened');
      link.removeClass('active');
    }
  });
});

/**********************************************
MODAL BOX
**********************************************/

$(function() {
  var body        = $('body');
  var modalClass  = $('.modal');
  var trigger     = $('.modal-trigger');
  var close       = $('.modal-close');

  trigger.each(function(index, element) {
    var id      = element.hash;
    var modal   = $(id);

    $(element).on('click', function(e) {
      modal.addClass('active');
      $('body').addClass('modal-opened');
    });
  });

  // Close link
  close.on('click', function(e) {
    modalClass.removeClass('active');
    body.removeClass('modal-opened');
    $(window).trigger('modalClose');
  });

  // Overlay click
  $(window).on('click', function(e) {
    $('.modal-box-wrap').each(function(index, element) {
      if (e.target === element) {
        modalClass.removeClass('active');
        body.removeClass('modal-opened');
        $(window).trigger('modalClose');
      }
    });
  });

  // Escape close
  $(window).on('keyup', function(e) {
    if (e.keyCode === 27) {
      modalClass.removeClass('active');
      body.removeClass('modal-opened');
      $(window).trigger('modalClose');
    }
  });

  $('.modal-box a').on('click', function() {
    $(window).trigger('modalClose');
  });
});

/**********************************************
MOBILE NAV
**********************************************/

$(function() {
  var body              = $('body');
  var mobileNav         = $('.mobile-nav');
  var mobileNavOverlay  = $('.mobile-nav-overlay');

  function showNav() {
    body.addClass('mobile-nav-opened');
  }

  function hideNav() {
    body.removeClass('mobile-nav-opened');
  }

  // Navigation button
  $('.nav-btn').on('click', function(e) {
    if (!body.hasClass('mobile-nav-opened')) {
      showNav();
    }
    else {
      hideNav();
    }
    return false;
  });

  // Mobile nav overlay close
  $('.mobile-nav-overlay').on('click', function(e) {
    hideNav();
  });

  $('.mobile-nav li a').each(function(e) {
    if ($(this).siblings('ul').length > 0) {
      $(this).append('<span class="more"></span>');
    }
  });

  // Mobile nav accordion
  $('.mobile-nav li .more').on('click', function(e) {
    var link = $(this).closest('a');
    var ul = link.siblings('ul');

    if (ul.length > 0) {
      if (!ul.hasClass('active')) {
        link.addClass('active');
        ul.addClass('active');
      } else {
        link.removeClass('active');
        ul.removeClass('active');
      }
      return false;
    }
  });
});

/**********************************************
COOKIEBAR
**********************************************/
/*
$(function() {
  var webCookie = 'ms_veletiny_cookiebar';
  var webCookiePrepend = 'body';
  var cookieisok = ReadCookie(webCookie);
  var testCookie = '';
  SetCookie('testCookie', 'ok', 365);
  var testCookie = ReadCookie('testCookie');

  $(function() {
    if (cookieisok != "ok" && testCookie == "ok") {
      $(webCookiePrepend).prepend("<div class='cookie-notice'>Pro účely analýzy návštěvnosti používáme nástroj Google Analytics, který využívá soubory cookies. Předpokládáme, že s použitím cookies souhlasíte. <a href='javascript:;' class='cookie-button'>SOUHLASÍM</a></div>")
    }

    $('.cookie-button').on('click', function() {
      $('.cookie-notice').hide();
      SetCookie(webCookie, 'ok', 365);
    });
  });
});
*/

/**********************************************
RESIZE END / SCROLL END
**********************************************/

var resizeEnd;
$(window).on('resize', function(e) {
  clearTimeout(resizeEnd);
  resizeEnd = setTimeout(function() {
    $(window).trigger('resizeEnd');
  }, 100);
});

var scrollEnd;
$(window).on('scroll', function(e) {
  clearTimeout(scrollEnd);
  scrollEnd = setTimeout(function() {
    $(window).trigger('scrollEnd');
  }, 100);
});

/**********************************************
FIXER
**********************************************/

$(window).on('load scroll',function() {
  if ($('.fixer').length) {
    $('.fixer-start').each(function() {
      var scrollTop = $(window).scrollTop();
      var winH = $(window).height();
      var fixElWrapper = $(this);
      var fixEl = fixElWrapper.find('.fixer');
      var offset = 0;
      if (fixEl.attr('data-offset')) {
        var offset = fixEl.data('offset');
      }

      var fixElWrapperTop = fixElWrapper.offset().top + offset;

      var fixElHeight = fixEl.height();
      var end = $('.fixer-end').height() + $('.fixer-end').offset().top - fixEl.height();

      // Top panel
      if (scrollTop > fixElWrapperTop) {
        fixEl.addClass('fixed');

        // Hide in the end
        if (scrollTop + 80 > end) {
          fixEl.fadeOut();
        }
        else {
          fixEl.fadeIn();
        }
      }
      else {
        fixEl.removeClass('fixed');
      }
    });
  }
});

/**********************************************
SIMPLE LIGHTBOX
**********************************************/

// Simple lightbox
var lightbox = new SimpleLightbox({
  elements: ".fancybox, a[href$='.jpg'], a[href$='.jpeg'], a[href$='.webp'], a[href$='.png'], a[href$='.gif'], [data-fancybox='gallery'], [data-purpose='play']"
});

// Swipe  
document.addEventListener('swiped-left', function(e) {
  if ($('html.slbActive').length > 0) {
    lightbox.next();
  }
});

document.addEventListener('swiped-right', function(e) {
  if ($('html.slbActive').length > 0) {
    lightbox.prev();
  }
});

/**********************************************
SLIDERS
**********************************************/

// Teaser slider
if ($('.teaser-slider').length > 0 ) {
  new Glide('.teaser-slider', {
    type: 'carousel',
    startAt: 0,
    perView: 1,
    focusAt: 'center',
    //animationDuration: 0,
    gap: 0,
    swipeThreshold: false,
    dragThreshold: false,
    autoplay: 5000,
    breakpoints: {
      991: {
        perView: 1
      },
      575: {
        perView: 1
      }
    }
  }).mount()
}

// History slider
if ($('.history-slider').length > 0 ) {
  new Glide('.history-slider', {
    type: 'carousel',
    startAt: 0,
    perView: 4,
    //focusAt: 'center',
    //animationDuration: 0,
    gap: 0,
    swipeThreshold: false,
    dragThreshold: false,
    breakpoints: {
      991: {
        perView: 3
      },
      575: {
        perView: 2
      }
    }
  }).mount()
}

/****************************************************************************************************************************************************************************************
FUNCTIONS
****************************************************************************************************************************************************************************************/

// Plynulý scroll ke kotvě
(function($) {
  $.fn.scrollToAnchor = function(settings) {

    settings = jQuery.extend({
      speed : 750
    }, settings);

    return this.each(function(){
      var caller = this
      $(caller).click(function (event) {
        event.preventDefault()
        var locationHref = window.location.href
        var elementClick = $(caller).attr("href")

        var destination = $(elementClick).offset().top;

        $("html:not(:animated),body:not(:animated)").animate({
            scrollTop: destination
          }, {
            duration: settings.speed,
            complete: function() {
              history.pushState(null, null, elementClick);
            }
          }
        );
        return false;
      });
    });
  }
})(jQuery);

// Cookies
function ReadCookie(name) {
  var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
}

function SetCookie(name, value, days) {
  var d = new Date;
  d.setTime(d.getTime() + 24*60*60*1000*days);
  document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}

function DeleteCookie(name) {
  setCookie(name, '', -1);
}

/*!
 * pure-swipe.js - v@version@
 * Pure JavaScript swipe events
 * https://github.com/john-doherty/pure-swipe
 * @inspiration https://stackoverflow.com/questions/16348031/disable-scrolling-when-touch-moving-certain-element
 * @author John Doherty <www.johndoherty.info>
 * @license MIT
 */
(function (window, document) {

  'use strict';

  // patch CustomEvent to allow constructor creation (IE/Chrome) - resolved once initCustomEvent no longer exists
  if ('initCustomEvent' in document.createEvent('CustomEvent')) {

    window.CustomEvent = function (event, params) {

      params = params || { bubbles: false, cancelable: false, detail: undefined };

      var evt = document.createEvent('CustomEvent');
      evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
      return evt;
    };

    window.CustomEvent.prototype = window.Event.prototype;
  }

  document.addEventListener('touchstart', handleTouchStart, false);
  document.addEventListener('touchmove', handleTouchMove, false);
  document.addEventListener('touchend', handleTouchEnd, false);

  var xDown = null;
  var yDown = null;
  var xDiff = null;
  var yDiff = null;
  var timeDown = null;
  var startEl = null;

  function handleTouchEnd(e) {

    // if the user released on a different target, cancel!
    if (startEl !== e.target) return;

    var swipeThreshold = parseInt(startEl.getAttribute('data-swipe-threshold') || '20', 10);    // default 10px
    var swipeTimeout = parseInt(startEl.getAttribute('data-swipe-timeout') || '500', 10);      // default 1000ms
    var timeDiff = Date.now() - timeDown;
    var eventType = '';

    if (Math.abs(xDiff) > Math.abs(yDiff)) { // most significant
      if (Math.abs(xDiff) > swipeThreshold && timeDiff < swipeTimeout) {
        if (xDiff > 0) {
          eventType = 'swiped-left';
        }
        else {
          eventType = 'swiped-right';
        }
      }
    }
    else {
      if (Math.abs(yDiff) > swipeThreshold && timeDiff < swipeTimeout) {
        if (yDiff > 0) {
          eventType = 'swiped-up';
        }
        else {
          eventType = 'swiped-down';
        }
      }
    }

    if (eventType !== '') {

      // fire event on the element that started the swipe
      startEl.dispatchEvent(new CustomEvent(eventType, { bubbles: true, cancelable: true }));

      //if (console && console.log) console.log(eventType + ' fired on ' + startEl.tagName);
    }

    // reset values
    xDown = null;
    yDown = null;
    timeDown = null;
  }

  function handleTouchStart(e) {

    // if the element has data-swipe-ignore="true" we stop listening for swipe events
    if (e.target.getAttribute('data-swipe-ignore') === 'true') return;

    startEl = e.target;

    timeDown = Date.now();
    xDown = e.touches[0].clientX;
    yDown = e.touches[0].clientY;
    xDiff = 0;
    yDiff = 0;
  }

  function handleTouchMove(e) {

    if (!xDown || !yDown) return;

    var xUp = e.touches[0].clientX;
    var yUp = e.touches[0].clientY;

    xDiff = xDown - xUp;
    yDiff = yDown - yUp;
  }

}(this, document));